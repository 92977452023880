import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../../config/AxiosInstance';
import {
    Container, Box, Typography, Grid, List, ListItem, ListItemIcon,
    ListItemText, Button, CircularProgress, Autocomplete, TextField, Stack
}
    from '@mui/material'
import { StyledTextfield, StyledFloatingLabelTextField } from '../../components/styled-components/inputField';
import styled from '@emotion/styled'
import Success from '../../assets/images/success.svg';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptchaButton from '../registration/ReCaptchaButton';
import { toast } from 'react-toastify';
import Loading from '../../components/common-components/CircularLoading';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';




function Registration({ handleOrderComplete, label }) {
    const isReCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';
    // eslint-disable-next-line
    const [captchaToken, setCaptchaToken] = useState(null); // Captcha token state
    const [isSubmitting, setIsSubmitting] = useState(false); // Submitting state
    const reDat = JSON.parse(sessionStorage.getItem('registration_data'))

    // list of objects that holds up the label and the value for the "number of months needed" dropdown
    const month = [
        { label: 'Month to Month', value: '1' },
        { label: 'Three Months + (Free San Diego Delivery)', value: '3' },
    ];

    const [inputField, setInputField] = useState('');
    const [input, setInput] = useState('');
    const [newValue, setNewValue] = useState('');


    // eslint-disable-next-line
    const resitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const [regData, setRegData] = useState({
        first_name: reDat ? reDat.first_name : '',
        last_name: reDat ? reDat.last_name : '',
        phone_number: reDat ? reDat.phone_number : '',
        email: reDat ? reDat.email : '',
        zip_code: reDat ? reDat.zip_code : '',
        number_of_months_needed: sessionStorage.getItem('number_of_months_needed') || '1',
        number_of_boxes: sessionStorage.getItem('number_of_boxes') || '1',
    }); // the registration data object. this will be sent to the api when "view pricing for bigbox" button is pressed
    const [zipError, setZipError] = useState(""); // zip error state
    const [validZip, setValidZip] = useState([]); // valid zips state
    const [firstNameError, setFirstNameError] = useState(""); // first name error state
    const [lastNameError, setLastNameError] = useState('');// last name error state
    const [phoneError, setPhoneError] = useState(''); // phone error state
    const [emailError, setEmailError] = useState(''); // email error state
    const ch = JSON.parse(sessionStorage.getItem('bigboxinfo'))?.charges.map(entry => entry); // list of charges object obtained from the session storage
    const effectExecutedRef = useRef(false); // value to keep the useEffect from executing more than one time
    const navigate = useNavigate(); // navigation function to navigate to desired page of the app
    const [resDat, setResDat] = useState({}) // response data state

    const findAmountById = (id) => {
        // extract the single object from the charges list if its id equals to the given id
        const charge = ch?.find(charge => charge.id === id);
        // returns the amount of the extracted object based on the id
        return charge ? charge.amount : null;

    }

    const calculateMerchandiseValue = (quantity, lock) => {
        if (quantity === undefined) {
            return '0.00';
        }
        const pricePerItem = Number(findAmountById("9"));
        if (lock) {
            return (pricePerItem * quantity).toFixed(2).toString();
        }
        else {
            return (pricePerItem).toFixed(2).toString();
        }
    };
    // sendData object state
    const [sendData1, setSendData1] = useState({
        zip_code: "",
        delivery_date: "",
        number_of_months_needed: sessionStorage.getItem('number_of_months_needed') || '1',
        number_of_boxes: sessionStorage.getItem('number_of_boxes') || '1',
        promotion_code: '',
        promotion_code_value: '0.00',
        rental_rate: findAmountById("2"),
        padlock_quantity: "0",
        padlock_price: calculateMerchandiseValue(0, false) || calculateMerchandiseValue(0, false)
    });
    //   const [sendData3, setSendData3] = useState(null);
    const [loading, setLoading] = useState(true); // loading state

    const boxCountMapping = {
        1: " Fits a studio apartment. Only pay for what you use.",
        2: " Fits about 2 rooms. Only pay for what you use.",
        3: " Fits about 3 rooms. Only pay for what you use.",
        4: " Fits about 4 rooms. Only pay for what you use.",
        5: " Fits about 5 rooms. Only pay for what you use.",
        6: " Fits about 6 rooms. Only pay for what you use.",
    };



    // Add useEffect to handle localStorage updates when regData changes
    // useEffect(() => {
    //     sessionStorage.setItem('number_of_months_needed', regData.number_of_months_needed);
    //     sessionStorage.setItem('number_of_boxes', regData.number_of_boxes);
    // }, [regData.number_of_months_needed, regData.number_of_boxes]);
    useEffect(() => {
        // Ensure stored values are used when component mounts
        const storedMonths = sessionStorage.getItem('number_of_months_needed');
        const storedBoxes = sessionStorage.getItem('number_of_boxes');

        if (storedMonths) {
            setSendData1(prev => ({
                ...prev,
                number_of_months_needed: storedMonths
            }));
        }

        if (storedBoxes) {
            setSendData1(prev => ({
                ...prev,
                number_of_boxes: storedBoxes
            }));
        }
    }, []); // Empty dependency array means this runs only on initial mount

    // function to display the phone number in (xxx) xxx-xxxx format
    const formatDisplayPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    //const sendData

    // sendData object state


    //const sendData


    // this useEffect is executed once to get the bigbox info from the api
    useEffect(() => {
        handleOrderComplete(false); // this makes sure that the order isn't completed yet when the user initially loads this page
        // funtion to get the valid zip value from the big box info
        const getZip = async () => {
            if (effectExecutedRef.current) return;
            effectExecutedRef.current = true; // making this value true here will prevent this useEffect from running more than one time
            try {
                const response = await AxiosInstance.get("/bigbox-info"); // api call
                sessionStorage.setItem('bigboxinfo', JSON.stringify(response.data)); // the response is stored in the session storage so that we dont need to call the api frequently
                // makes sure the "delivery_rates" property from the response object is an array just in case
                if (Array.isArray(response.data.delivery_rates)) {
                    const zipCodes = response.data.delivery_rates.map(entry => entry.zip);
                    setValidZip(zipCodes); // valid zip codes are fetched from the api and set successfully
                } else {
                    toast.error('Unexpected response format');
                }
            }
            // catches if theres any error in the api call and displays to the toast
            catch (error) {
                toast.error(error)
            }
        };
        // call of the getZip function
        getZip();
        // eslint-disable-next-line
    }, [])

    // function to send the data to the api for creating leads
    // Modified sendData function to ensure storage values are included
    const sendData = async (data) => {
        try {
            // Include localStorage values in API call
            const dataWithStorage = {
                ...data,
                number_of_months_needed: sendData1.number_of_months_needed,
                number_of_boxes: sendData1.number_of_boxes
            };

            const response = await AxiosInstance.post("/bigbox-create-leads", dataWithStorage);
            if (response.data.message === "Leads info saved successfully") {
                sessionStorage.setItem('lead_id', response.data.data.lead_id);
            }
            return response.data.data.lead_id;
        } catch (error) {
            toast.error(error);
            return 0
        }
    };


    const createQuote = async (lead_id) => {
        const quoteDat = {
            lead_id: lead_id,
            quote_id: sessionStorage.getItem('quote_id') || '0',
            userid: '10001',
            protection_plan: "0",
            zip_code: regData.zip_code,
            delivery_date: '',
            number_of_boxes: sendData1.number_of_boxes,
            number_of_months_needed: sendData1.number_of_months_needed,
            delivery_time: '',
            storage_location: 'My Location',
            pickup_date: '',
            referral_source: 'bb',
            rental_rate: 79,
            delivery_fee: "99.00",
            delivery_discount: "0.00",
            promotion_code: "",
            promotion_code_value: "0.00",
            one_time_fee: 0,
            merchandise_fee: 0,
            padlock_quantity: "0",
            padlock_price: "0.00",
            protection_plan_quantity: "0",
            protection_plan_price: "0.00",
            tax: 0,
            total: 205,
            customer: {
                first: regData.first_name,
                last: regData.last_name,
                phone_number: regData.phone_number,
                phone: {
                    area: regData.phone_number.slice(0, 3),
                    prefix: regData.phone_number.slice(3, 6),
                    number: regData.phone_number.slice(6)
                },
                email: regData.email,
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: regData.zip_code
            }
        }
        try {
            sessionStorage.setItem('quoteData', JSON.stringify(quoteDat))
            const response = await AxiosInstance.post("/bigbox-create-quote", quoteDat);
            sessionStorage.setItem('quote_id', response.data.data.bigbox_quote_id);
            sessionStorage.setItem('prospect_id', response.data.data.bigbox_prospect_id);
            return true
        }
        catch (err) {
            toast.error(err);
            return true
        }
    }

    const handleContinue = async (token) => {
        if (!sessionStorage.getItem('bigboxinfo')) {
            toast.error('Error: We are unable to provide quote now. Please call us for a quote.');
            // window.location.reload()
            return; // Exit early if 'bigboxinfo' is not found
        }
        if (JSON.parse(sessionStorage.getItem('bigboxinfo')).error) {
            toast.error('Error: We are unable to provide quote now. Please call us for a quote.');
            return;
        }
        let isValid = true;

        // Validate zip code
        if (validZip.includes(regData.zip_code)) {
            setZipError('');
        } else {
            setZipError("Big Box Storage is not available for this zip code");
            isValid = false;
        }

        // Validate first name
        if (!regData.first_name.trim()) {
            setFirstNameError('First Name Required');
            isValid = false;
        } else {
            setFirstNameError('');
        }

        // Validate last name
        if (!regData.last_name.trim()) {
            setLastNameError('Last Name Required');
            isValid = false;
        } else {
            setLastNameError('');
        }

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(regData.email)) {
            setEmailError("Invalid email format");
            isValid = false;
        } else {
            setEmailError('');
        }

        // Validate phone number
        const phonePattern = /^(\d{10})$/;
        if (!phonePattern.test(regData.phone_number)) {
            setPhoneError("Phone number must be 10 digits");
            isValid = false;
        } else {
            setPhoneError('');
        }

        // Only perform reCAPTCHA validation if it is enabled
        if (isValid) {
            setIsSubmitting(true);
            const isReCaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';

            if (isReCaptchaEnabled) {
                setCaptchaToken(token);
                try {
                    const url = process.env.REACT_APP_BIGBOX_GLOBAL_URL + '/process-recaptcha';

                    // Create the form data object
                    const formData = new URLSearchParams();
                    formData.append('secret', process.env.REACT_APP_RECAPTCHA_SECRET_KEY);
                    formData.append('token', token);

                    // Send the request using fetch
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'x-api-key': process.env.REACT_APP_BIGBOX_API_KEY,  // Add the API key header
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: formData
                    });

                    // Check if the response is ok (status code 200-299)
                    if (response.ok) {
                        // Parse the response JSON
                        const data = await response.json();

                        // Check the reCAPTCHA score
                        if (data.success && data.score >= 0.5) {
                            // Score is valid, continue with form submission
                            const cleaned = regData.phone_number.replace(/\D/g, '');
                            const formattedPhoneNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                            let lead = null;
                            sessionStorage.setItem('formatted_phone_number', formattedPhoneNumber);
                            lead = await sendData(regData);
                            createQuote(lead);
                            sessionStorage.setItem('registration_data', JSON.stringify(regData));
                            sessionStorage.setItem('s3data', JSON.stringify(regData));
                            sessionStorage.setItem('delcalzip', regData.zip_code);
                            sessionStorage.setItem('calcData', JSON.stringify({
                                promotion_code: '',
                                promotion_code_value: "",
                                rental_rate: '79.00',
                                number_of_boxes: sendData1.number_of_boxes,
                                number_of_months_needed: sendData1.number_of_months_needed,
                                zip_code: regData.zip_code
                            }));
                            // toast.success('ReCaptcha verification passed!');
                            setTimeout(() => {


                                navigate(`/rent-online`);
                            }, 500);
                            console.log("navigating to rent-online");
                        } else {
                            // Score is too low, show an error message or handle accordingly
                            toast.error('ReCaptcha verification failed');
                            setIsSubmitting(false);
                        }
                    } else {
                        toast.error('HTTP Error: ' + response.status);
                        setIsSubmitting(false);
                    }
                } catch (e) {
                    toast.error('Fetch error');
                    setIsSubmitting(false);
                }
            } else {
                // If reCAPTCHA is not enabled, proceed with form submission directly
                const cleaned = regData.phone_number.replace(/\D/g, '');
                const formattedPhoneNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                let lead = null;
                sessionStorage.setItem('formatted_phone_number', formattedPhoneNumber);
                lead = await sendData(regData);
                createQuote(lead);
                sessionStorage.setItem('registration_data', JSON.stringify(regData));
                sessionStorage.setItem('s3data', JSON.stringify(regData));
                sessionStorage.setItem('delcalzip', regData.zip_code);
                sessionStorage.setItem('calcData', JSON.stringify({
                    promotion_code: '',
                    promotion_code_value: "",
                    rental_rate: '79.00',
                    number_of_boxes: sendData1.number_of_boxes,
                    number_of_months_needed: sendData1.number_of_months_needed,
                }));
                //   toast.success('Form submitted successfully!');
                setTimeout(() => {
                    navigate(`/rent-online`);
                }, 500);
            }
        }
    };


    // Modified handleForm2Change to update localStorage
    const handleForm2Change = (input) => (event, value) => {
        if (input === 'number_of_months_needed') {
            const selectedValue = value ? value.value : '1';

            // Explicitly update session storage
            sessionStorage.setItem('number_of_months_needed', selectedValue);


            setSendData1((prevState) => ({
                ...prevState,
                [input]: selectedValue,
            }));
        }
    };

    //imported function

    // styling function for the dropdown
    const StyledAutocomplete = styled(Autocomplete)(({ theme }) => {
        return {
            '& .MuiInputBase-root': {
                fontSize: '14px',
                borderRadius: '8px',
                '& .MuiInputBase-input': {
                    padding: 0
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.primary.main}`,
                },
                '&.Mui-disabled': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: theme.palette.background.disabled,
                        borderColor: 'transparent'
                    }
                }
            }
        }
    });

    // styling function for the info
    const StyledInfo = styled(Typography)(({ theme }) => {
        return {
            backgroundColor: theme.palette.background.grey2,
            color: '#6B6B6B',
            borderRadius: '6px',
            padding: '8px 10px',
            display: 'inline-block',
            marginBlock: '12px',
            '@media screen and (max-width: 600px)': {
                textAlign: 'center'
            }
        }
    });


    // Modified handleBoxIncrement to update localStorage
    const handleBoxIncrement = () => {
        setSendData1((prevState) => {
            const newBoxCount = (Number(prevState.number_of_boxes) + 1).toString();
            sessionStorage.setItem('number_of_boxes', newBoxCount);
            return {
                ...prevState,
                number_of_boxes: newBoxCount,
            };
        });
    };

    // Modified handleBoxDecrement to update localStorage
    const handleBoxDecrement = () => {
        setSendData1((prevState) => {
            const newBoxCount = (Number(prevState.number_of_boxes) - 1).toString();
            sessionStorage.setItem('number_of_boxes', newBoxCount);
            return {
                ...prevState,
                number_of_boxes: newBoxCount,
            };
        });
    };

    // function to handle the change in the protection choice change




    const DeliveryFeeDisplay = ({ loading, deliveryFee }) => {
        return (
            <>
                ${loading ? (
                    <CircularProgress size={10} color='inherit' />
                ) : (
                    <>
                        {deliveryFee !== undefined && deliveryFee !== null ? deliveryFee : ""}
                    </>
                )} Delivery Fee
            </>
        );
    }

    // styling function for the count increase or decrease button
    const StyledCountButton = styled(Button)(({ theme }) => {
        return {
            padding: 1,
            backgroundColor: '#EAF5FF',
            border: '1px solid #014D97',
            color: theme.palette.primary.main,
            borderRadius: '8px',
            '&.Mui-disabled': {
                backgroundColor: theme.palette.background.disabled,
                color: '#CECECE',
                borderColor: '#CECECE'
            }
        }
    });

    //imported function

    // Modified handleChange to update localStorage
    const handleChange = (inputField) => (e) => {
        const { value } = e.target;
        const newValue = inputField === 'phone_number' ? value.replace(/\D/g, '').slice(0, 10) : value;

        // Newly Added zip code Fix start

         // Immediately update sessionStorage if zip code changes
    if (inputField === 'zip_code') {
        sessionStorage.setItem('delcalzip', value);
        // Also update in calcData if it exists
        const calcData = sessionStorage.getItem('calcData');
        if (calcData) {
            const updatedCalcData = JSON.parse(calcData);
            updatedCalcData.zip_code = value;
            sessionStorage.setItem('calcData', JSON.stringify(updatedCalcData));
        }
    }


        // Newly Added zip code Fix end

        setRegData(prev => ({
            ...prev,
            [inputField]: newValue
        }));
    }


    // Update localStorage for specific fields
    if (inputField === 'number_of_months_needed' || input === 'number_of_boxes') {
        sessionStorage.setItem(input, newValue);
    }


    // const handleCaptchaVerify = (token) => {
    //     setCaptchaToken(token);
    // };


    // function for button stylings
    const StyledButton = styled(Button)(({ theme, variant }) => {
        return {
            '&.MuiButtonBase-root': {
                backgroundColor: variant === 'contained' ? theme.palette.secondary.main : 'transparent',
                border: `1px solid ${theme.palette.secondary.main}`,
                color: variant === 'contained' ? theme.palette.common.white : theme.palette.secondary.main,
                padding: '12px 24px',
                borderRadius: '10px',
                fontFamily: `'program', sans- serif`,
                fontSize: '1.35rem',
                fontWeight: '700',
                letterSpacing: '1px',
                textTransform: 'capitalize',
                '&:hover': {
                    backgroundColor: variant === 'contained' ? '#cc5a00' : null
                }
            }
        }
    })

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            <Box py={{ xs: 5, md: 5 }} bgcolor={'background.grey1'}>

                <Box width={{ xs: '100%', md: '75%' }} m={'auto'}>
                    <Grid container pt={0} spacing={2.5}>
                        <Grid item className='test' sx={{ paddingTop: '0px !important' }} xs={12} md={12}>
                            <Box
                                margin={'auto'} width={{ xs: '100%', md: '100%' }} >
                                <Box>
                                    {/* <Typography py={1} mb={{ xs: 1, sm: 2 }} variant='h1' color={'primary.dark'} textAlign={{ xs: 'center' }}>{label.header}

                                </Typography> */}







                                    {/* New Label Adding   20-01-2025*/}
                                    {/*
                                    <Grid item xs={12} sm={6}>
                                        <List dense >
                                            <ListItem sx={{ color: '#FF7100', textAlign: 'center', fontWeight: 'bold', fontSize: '58px' }} >
                                                <ListItemText

                                                    primary={label.bullet3left}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid> */}
                                    {/* New Label Adding 20-01-2025 */}

                                    {/* Form */}
                                    <Box component='form'>
                                        <Box p={{ xs: 2, md: 4 }} bgcolor={'common.white'} boxShadow={2} borderRadius={'16px'} border={'1px solid #EFEFEF'}>
                                            <Grid container rowSpacing={2} columnSpacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        {/* <Typography variant='label1' component={'label1'} color={'text.label'}>First Name</Typography> */}
                                                        <StyledFloatingLabelTextField id="first-name"
                                                            className='styled-textfield' disabled={isSubmitting} variant="filled"
                                                            label="First Name"
                                                            size='small'

                                                            placeholder='Enter First Name' fullWidth value={regData.first_name} onChange={handleChange('first_name')} autoFocus />
                                                        {firstNameError && <Typography variant='error'>{firstNameError}</Typography>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        {/* <Typography variant='label1' component={'label1'} color={'text.label'}>Last Name</Typography> */}
                                                        <StyledFloatingLabelTextField id="last-name" className='styled-textfield' disabled={isSubmitting} variant="filled"
                                                            label="Last Name"
                                                            size='small' placeholder='Enter Last Name' fullWidth value={regData.last_name} onChange={handleChange('last_name')} />
                                                        {lastNameError && <Typography variant='error'>{lastNameError}</Typography>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        {/* <Typography variant='label1' component={'label1'} color={'text.label'}>Email</Typography> */}
                                                        <StyledFloatingLabelTextField id="email" className='styled-textfield' disabled={isSubmitting} variant="filled" placeholder='Enter Email' fullWidth value={regData.email} onChange={handleChange('email')} label="Email" size='small' />
                                                        {emailError && <Typography variant='error'>{emailError}</Typography>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        {/* <Typography variant='label1' component={'label1'} color={'text.label'}>Phone Number</Typography> */}
                                                        <StyledFloatingLabelTextField
                                                            id="phone-number"
                                                            className='styled-textfield'
                                                            disabled={isSubmitting}
                                                            variant="filled"
                                                            size='small'
                                                            label="Phone Number"
                                                            placeholder='(_ _ _) _ _ _-_ _ _ _'
                                                            fullWidth value={regData.phone_number}
                                                            onChange={handleChange('phone_number')}
                                                            autoComplete='off'
                                                            inputProps={{
                                                                value: formatDisplayPhoneNumber(regData.phone_number),
                                                                sx: { '::placeholder': { color: '#000000 !important', opacity: 2 } },
                                                                form: {
                                                                    autocomplete: 'off',
                                                                },
                                                            }} // prevents the auto fill of this field
                                                        />
                                                        {phoneError && <Typography variant='error'>{phoneError}</Typography>}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        {/* <Typography variant='label1' component={'label1'} color={'text.label'}>Delivery Zip Code</Typography> */}
                                                        <StyledFloatingLabelTextField id="zip-code"
                                                            label="Delivery Zip Code"
                                                            className='styled-textfield' disabled={isSubmitting} variant="filled"
                                                            size='small' placeholder='Enter Zip Code' fullWidth value={regData.zip_code} onChange={handleChange('zip_code')} />
                                                        {zipError && <Typography variant='error'>{zipError}</Typography>}
                                                    </Box>
                                                </Grid>

                                                {/* new field */}

                                                <Grid item xs={12} md={12}>
                                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Box>
                                                                <Typography mb={{ sm: 1 }} color={'text.label'} variant='label1'> How long will you need storage ?</Typography>

                                                                {/* Autocomplete */}
                                                                {/* <StyledAutocomplete
                                                            className="styled-autocomplete"
                                                            id="number_of_months_needed"
                                                            options={month.slice(1)} // Only show "Three Months +" as selectable
                                                            value={month.find((option) => option.value === sendData.number_of_months_needed) || month[0]}
                                                            onChange={handleForm2Change('number_of_months_needed')}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Month to Month" // Default placeholder
                                                                />
                                                            )}
                                                        /> */}
                                                                <StyledAutocomplete
                                                                    className='styled-autocomplete'
                                                                    id="number_of_months_needed"
                                                                    options={month}
                                                                    size='small'
                                                                    value={month.find(option => option.value === sessionStorage.getItem('number_of_months_needed')) || month[0]}
                                                                    onChange={handleForm2Change('number_of_months_needed')}
                                                                    // sx={{ width: { xs: '100%', sm: 250 }, my: '16px' }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>



                                                {/* new field */}

                                                {/* new Box field  number of boxes */}

                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        <Typography
                                                            mb={{ xs: 1, sm: 2 }}
                                                            color={"text.label"}
                                                            variant="label1"
                                                        >
                                                            How many boxes will you need?
                                                        </Typography>

                                                        {/* Count Button */}
                                                        <Box>
                                                            <Stack
                                                                direction={"row"}
                                                                gap={2}
                                                                justifyContent={{ xs: "center", sm: "start" }}
                                                            >
                                                                {/* Decrement Button */}
                                                                <StyledCountButton
                                                                    disabled={Number(sendData1.number_of_boxes) === 1}
                                                                    onClick={handleBoxDecrement}
                                                                >
                                                                    <RemoveIcon />
                                                                </StyledCountButton>

                                                                {/* Number Display */}
                                                                <StyledTextfield
                                                                    sx={{ maxWidth: 60, textAlign: "center" }}
                                                                    value={sendData1.number_of_boxes}
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                        style: { textAlign: "center" },
                                                                    }}
                                                                />

                                                                {/* Increment Button */}
                                                                <StyledCountButton onClick={handleBoxIncrement}>
                                                                    <AddIcon />
                                                                </StyledCountButton>
                                                            </Stack>
                                                        </Box>

                                                        {/* Delivery Discount Information */}
                                                        <StyledInfo sx={{ marginTop: '18px !important' }} width={{ xs: "100%", sm: "auto" }}>
                                                            {sendData.number_of_months_needed === "3" ? (
                                                                <>$99 Delivery Discount!</>
                                                            ) : (

                                                                // <StyledInfo sx={{margin:'0px !important',padding:'0px !important'}} width={{ xs: '100%', sm: 'auto' }}>${findAmountById("2")} per Box
                                                                // </StyledInfo>

                                                                <Typography className='tes' color="#FF4500" variant="body2">
                                                                    {sendData1.number_of_boxes > 3
                                                                        ? "etc"
                                                                        : boxCountMapping[sendData1.number_of_boxes] || "Select boxes to see details"}
                                                                </Typography>


                                                            )}
                                                        </StyledInfo>

                                                        {/* Dynamic Text Display */}
                                                        <Box mt={1}>
                                                            {/* <Typography className='tes' color="#FF4500" variant="body2">
                                                                {sendData1.number_of_boxes > 3
                                                                    ? "etc"
                                                                    : boxCountMapping[sendData1.number_of_boxes] || "Select boxes to see details"}
                                                            </Typography> */}
                                                        </Box>
                                                    </Box>
                                                </Grid>






                                            </Grid>

                                            {/* new Box field  number of boxes*/}




                                        </Box>
                                        <Box py={2.5} textAlign={'center'}>
                                            {isReCaptchaEnabled ? (
                                                <ReCaptchaButton onSubmit={handleContinue} />
                                            ) : (
                                                <StyledButton variant='contained' onClick={handleContinue} disabled={isSubmitting}>
                                                    {isSubmitting ? <CircularProgress size={24} /> : label.button}
                                                </StyledButton>
                                            )}
                                        </Box>

                                    </Box>

                                    <Typography
                                        py={0}
                                        mb={{ xs: 1, sm: 2 }}
                                        variant="h1"
                                        color="primary.dark"
                                        textAlign="center"
                                        sx={{
                                            fontSize: {
                                                xs: '1.5rem', // Smaller font size for mobile screens
                                                sm: '2.125rem', // Default size for larger screens
                                            },
                                            lineHeight: 1.2, // Adjust line height for better spacing
                                            whiteSpace: 'normal', // Allow wrapping for mobile
                                        }}
                                    >
                                        {/* Responsive Styling */}
                                        <span
                                            style={{
                                                display: 'block',
                                            }}
                                        >
                                            Need help?{' '}
                                            <a
                                                href="tel:6194365020"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'inherit',
                                                }}
                                            >


                                                <span
                                                    style={{
                                                        display: 'inline', // Inline by default
                                                    }}
                                                >
                                                    Call us here <u>619-436-5020</u>
                                                </span>
                                            </a>
                                        </span>

                                        <style>
                                            {`
      @media (max-width: 600px) {
        span { 
          display: block !important;
          text-align: center;
        }

        a span {
          display: block !important; 
          /* Break the number onto the second line */
        }

        a span {
        //   text-decoration: underline !important;
        //   font-style: italic !important;
          hover-text: underline !important;
        }
          a span i:hover{
          text-decoration: underline;
          }
      }
    `}
                                        </style>
                                    </Typography>
                                </Box>
                            </Box>


                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </GoogleReCaptchaProvider>
    )
}

export default Registration
