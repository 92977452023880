import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import { Typography, Link, Stack,Avatar } from '@mui/material';

function Contact({label}) {
    return (
        <>
            <Link href="#" underline="none" color={'common.black'} sx={{display:{xs:'block',md:'none'}}}  >
                <Stack direction={'row'} alignItems={'center'}  spacing={1.5} useFlexGap >                   
                    {/* <Avatar   sx={{ bgcolor: 'primary.main',width:'20px',height:'20px',padding:'14px' }} variant="square">
   <PhoneIcon  sx={{fontSize:16}} />
</Avatar> */}
                    <Typography color={'secondary.main'} fontWeight={600}>{label}</Typography>
                </Stack>
            </Link>
        </>
    )
}

export default Contact